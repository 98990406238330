import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, List, Section, Em } from "@quarkly/widgets";
const defaultProps = {
	"background-color": "--dark",
	"text-align": "center",
	"padding": "32px 0",
	"quarkly-title": "Footer-1"
};
const overrides = {
	"list": {
		"kind": "List",
		"props": {
			"margin": "0px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"href": "/index",
			"font": "--lead",
			"children": "ГОЛОВНА СТОРІНКА"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"display": "block",
			"href": "/contacts",
			"children": "КОНТАКТИ"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"href": "/services",
			"font": "--lead",
			"children": "ПОСЛУГИ"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/",
			"text-decoration-line": "none",
			"variant": "--base",
			"color": "--light",
			"hover-color": "--primary",
			"font": "--headline3",
			"children": "Керамічна майстерня ARTPOTTERY"
		}
	}
};

const Header12 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</List>
		<Link {...override("link3")} />
		{children}
	</Section>;
};

Object.assign(Header12, { ...Section,
	defaultProps,
	overrides
});
export default Header12;